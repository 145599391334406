import React from "react"
import { graphql } from "gatsby"

import styled from "styled-components"

import Layout from "../components/layouts/courseLandingLayout"
import Navbar from "../components/navbars/courseLandingNavbar"
import SearchEngine from "../components/SearchEngine"

const PostHeaderStyles = styled.header`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 15px;

  .meta-info {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .author {
    text-transform: lowercase;
    font-style: italic;
  }

  p {
    text-transform: uppercase;
    font-size: 14px;
  }

  h1 {
    margin-bottom: 0.2em;
    max-width: 900px;
    margin: 0 auto;
  }
  .date {
    font-size: 11px;
    line-height: 100%;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0.2em;
    display: block;
    text-align: center;
    padding-bottom: 20px;
    font-family: "open-sans-regular";
  }
  .postMeta {
    font-size: 1.4rem;
    font-style: italic;
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    justify-content: start;
    & > * {
      :not(:last-child):after {
        content: "×";
        margin-left: 1rem;
        color: var(--yellow);
        font-weight: 600;
        font-style: normal;
      }
    }
  }
`

const Article = styled.article`
  margin-top: 120px;
`

const GuideTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const featureImageStyles = {
    maxWidth: "700px",
    margin: "50px auto 30px auto",
  }

  const MainBlogContent = styled.div`
    h3 {
      padding-bottom: 20px;
      padding-top: 40px;
    }

    h5 {
      padding-bottom: 15px;
      padding-top: 20px;
      font-family: roboto-mono;
      font-weight: bold;
    }

    li {
      margin-left: 25px;
      font-family: roboto-mono;

      p {
        margin-bottom: 0;
      }
    }

    padding-top: 40px;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 400px;

    p {
      margin-bottom: 30px;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  `

  const renderCategories = post.frontmatter.categories.map((category, i) => {
    if (i + 1 === post.frontmatter.categories.length) {
      return category
    } else {
      return category + ", "
    }
  })

  return (
    <Layout location={location} title={siteTitle}>
      <Navbar location={location} article={true} />
      <SearchEngine
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Article>
        <PostHeaderStyles>
          <h1>{post.frontmatter.title}</h1>
          <div className="meta-info">
            <p>
              <span className="author">Written by:</span>{" "}
              {post.frontmatter.author} | {renderCategories}{" "}
            </p>
          </div>
          <hr></hr>
        </PostHeaderStyles>

        <MainBlogContent dangerouslySetInnerHTML={{ __html: post.html }} />
        <footer></footer>
      </Article>
    </Layout>
  )
}

export default GuideTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        author
        categories
      }
    }
  }
`
